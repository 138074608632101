import { useState } from "react";

const EXPERIMENT_SEED_KEY = "experimentSeed";

function getUUID() {
  try {
    return crypto.randomUUID();
  } catch (e) {
    // Fallback for older browsers
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }
}

const useExperimentSeed = () => {
  const [experimentSeed] = useState<string>(() => {
    try {
      let storedExperimentSeed = localStorage.getItem(EXPERIMENT_SEED_KEY);
      if (!storedExperimentSeed) {
        storedExperimentSeed = getUUID();
        localStorage.setItem(EXPERIMENT_SEED_KEY, storedExperimentSeed);
      }
      return storedExperimentSeed;
    } catch (e) {
      return getUUID();
    }
  });

  return experimentSeed;
};

export default useExperimentSeed;
