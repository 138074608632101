import {
  Application,
  Lead,
  MaximumBudget,
  PartnerCRMApplication,
} from "@justhome/common/api/core";
import { ApplicationFormValues } from "@lib/mapApplicationData/types";
import { BudgetCheckFormValues } from "@modules/application/lib/budgetCheck/types";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  Reducer,
  createContext,
  useReducer,
} from "react";
import { DeepPartial } from "react-hook-form";
import { StepId } from "../components/FormSteps/FormSteps";

export type FormState<T> = {
  values: { [key: StepId]: DeepPartial<T> };
  history: StepId[];
};

export interface ApplicationProcessState {
  lead?: Lead;
  application?: Application;
  maximumBudget?: MaximumBudget;
  applicationForm?: ApplicationFormValues;
  budgetCheckForm?: BudgetCheckFormValues;
  partnerApplicationForm?: PartnerCRMApplication;
  applicationFormState: FormState<ApplicationFormValues>;
  budgetCheckFormState: FormState<BudgetCheckFormValues>;
  partnerApplicationFormState: FormState<PartnerCRMApplication>;
}

export type Actions =
  | {
      type: "SET_APPLICATION_FORM";
      form: ApplicationFormValues;
    }
  | {
      type: "SET_APPLICATION_FORM_STATE";
      form: FormState<ApplicationFormValues>;
    }
  | {
      type: "SET_PARTNER_APPLICATION_FORM_STATE";
      form: FormState<PartnerCRMApplication>;
    }
  | {
      type: "SET_BUDGET_CHECK_FORM";
      form: BudgetCheckFormValues;
    }
  | {
      type: "SET_BUDGET_CHECK_FORM_STATE";
      form: FormState<BudgetCheckFormValues>;
    }
  | {
      type: "SET_PARTNER_APPLICATION_FORM";
      applicationForm: PartnerCRMApplication;
    }
  | {
      type: "SET_MAXIMUM_BUDGET";
      maximumBudget: MaximumBudget;
    }
  | {
      type: "RESET_FORM";
    }
  | {
      type: "SET_APPLICATION";
      application: Application;
    }
  | {
      type: "DELETE_APPLICATION";
    }
  | {
      type: "RESET";
    }
  | {
      type: "SET_LEAD";
      lead: Lead;
    }
  | {
      type: "SET_APPLICATION_FORM_VALUE";
      value: DeepPartial<ApplicationFormValues>;
      stepId: StepId;
    };

const defaultState: ApplicationProcessState = {
  applicationFormState: {
    history: [],
    values: {},
  },
  budgetCheckFormState: {
    history: [],
    values: {},
  },
  partnerApplicationFormState: {
    history: [],
    values: {},
  },
};

export const reducer: Reducer<ApplicationProcessState, Actions> = (
  state,
  action,
) => {
  switch (action.type) {
    case "RESET":
      return { ...defaultState };
    case "SET_APPLICATION_FORM_STATE":
      return { ...state, applicationFormState: action.form };
    case "SET_BUDGET_CHECK_FORM":
      return { ...state, budgetCheckForm: action.form };
    case "SET_BUDGET_CHECK_FORM_STATE":
      return { ...state, budgetCheckFormState: action.form };
    case "SET_PARTNER_APPLICATION_FORM_STATE":
      return { ...state, partnerApplicationFormState: action.form };
    case "SET_PARTNER_APPLICATION_FORM":
      return { ...state, partnerApplicationForm: action.applicationForm };
    case "SET_MAXIMUM_BUDGET":
      return { ...state, maximumBudget: action.maximumBudget };
    case "RESET_FORM":
      return {
        ...state,
        applicationFormState: defaultState.applicationFormState,
      };
    case "SET_APPLICATION_FORM_VALUE":
      return {
        ...state,
        applicationFormState: {
          ...state.applicationFormState,
          values: {
            ...state.applicationFormState.values,
            [action.stepId]: action.value,
          },
        },
      };
    case "SET_APPLICATION":
      return { ...state, application: action.application };
    case "DELETE_APPLICATION":
      return { ...state, application: undefined };
    case "SET_LEAD":
      return {
        ...state,
        lead: action.lead,
      };
    default:
      return state;
  }
};

export interface ApplicationContextValue {
  state: ApplicationProcessState;
  dispatch: Dispatch<Actions>;
}

export const ApplicationContext = createContext<ApplicationContextValue>({
  state: defaultState,
  dispatch: () => {},
});

ApplicationContext.displayName = "Application";

export const ApplicationProvider: FC<
  PropsWithChildren<{ initialState?: ApplicationProcessState }>
> = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(reducer, initialState || defaultState);

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </ApplicationContext.Provider>
  );
};
