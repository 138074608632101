import graphql from "../graphql";

export const SIMPLE_IMAGE = graphql`
  fragment SIMPLE_IMAGE on UploadFileEntityResponse {
    __typename
    data {
      attributes {
        url
        alternativeText
        width
        height
        mime
      }
    }
  }
`;

export default SIMPLE_IMAGE;
