import { NextPage } from "next";
import { useTranslation } from "next-i18next";
export interface ErrorPageProps {
  serverError?: Error;
}

const ErrorPage: NextPage<ErrorPageProps, Record<string, never>> = () => {
  const { t } = useTranslation();

  return (
    <div className="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center">
      <h1 className="my-3">{t("wrong_message")}</h1>
    </div>
  );
};

export default ErrorPage;
