import { logger } from "@justhome/common";
import ButtonId from "@lib/buttonId";
import { FATHOM_CLICK_EVENTS } from "@lib/constants";
import { isProd, isTest } from "@lib/settings";
import { getFromStorage, saveToStorage } from "@lib/utilities";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { fathom } from "@justhome/common";

declare global {
  interface Window {
    customReferrer?: string;
  }
}

const customReferrerTimeout = 60 * 1000 * 30;

const Fathom = () => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const fathomEvent = router.query.fathomEvent as string;

    if (fathomEvent) {
      /* We enforce it so that typos still get send to Fathom */
      fathom.trackEvent(fathomEvent);
      delete router.query.fathomEvent;
      router.replace(router);
    }
  }, [router.isReady, router]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const eventName =
        FATHOM_CLICK_EVENTS[(event.target as HTMLElement)?.id as ButtonId];
      if (eventName) {
        if (!isProd) {
          /* eslint-disable-next-line no-console */
          console.log("Fathom: tracking event", eventName);
        }
        fathom.trackEvent(eventName);
      }
    };
    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    window.customReferrer = window.document.referrer;

    try {
      Object.defineProperty(window.document, "referrer", {
        get() {
          return window.customReferrer;
        },
      });
    } catch (error) {
      logger.error(`Error in ${Fathom.name} component.`, error);
    }

    const lastFathomPageview = getFromStorage("last-fathom-pageview");
    if (lastFathomPageview) {
      const time = Number(lastFathomPageview);
      if (Date.now() - time < customReferrerTimeout) {
        window.customReferrer = window.location.toString();
      }
    }
  }, []);

  useEffect(() => {
    function onRouteChangeComplete() {
      window.customReferrer = window.location.toString();

      saveToStorage("last-fathom-pageview", Date.now().toString());
      fathom.trackPageview();
    }

    const isEnabled = !isTest;

    if (isEnabled) {
      router.events.on("routeChangeComplete", onRouteChangeComplete);
    }

    return () => {
      if (isEnabled) {
        router.events.off("routeChangeComplete", onRouteChangeComplete);
      }
    };
  }, [router.events]);

  return null;
};

export default Fathom;
