import { isServer } from "./shared";
import { Tracker } from "./types";

interface Umami {
  track(
    eventName: string,
    options?: {
      props?: Record<string, string | number | boolean | undefined>;
    },
  ): void;
}

declare global {
  interface Window {
    umami?: Umami;
  }
}

const queue: Parameters<Umami["track"]>[] = [];

const umamiDisabledKey = "umami.disabled";

class UmamiTracker implements Tracker {
  init() {}

  isEnabled(): boolean {
    if (isServer) {
      return false;
    }

    return Boolean(localStorage.getItem(umamiDisabledKey));
  }

  trackEvent(
    eventName: string,
    props?: Record<string, string | number | boolean | undefined>,
  ) {
    if (isServer) {
      return;
    }

    if (!this.isEnabled()) {
      /* eslint-disable-next-line no-console */
      console.debug(`Tracking umami event: %c${eventName}`, "color: #1A9883");
    }

    if (!window.umami) {
      queue.push([eventName, props]);
      return;
    }

    while (queue.length) {
      const args = queue.shift()!;
      window.umami.track(...args);
    }

    window.umami.track(eventName, props);
  }

  disable(): void {
    localStorage.setItem(umamiDisabledKey, "1");
  }
}

export default UmamiTracker;
