const parseCookie = (cookie: string = ""): Record<string, string> =>
  cookie
    .split(";")
    .map((part) => part.split("="))
    .reduce(
      (result, [key, value]: string[]) => {
        result[decodeURIComponent(key?.trim() ?? "") as string] =
          decodeURIComponent(value?.trim() ?? "");
        return result;
      },
      {} as Record<string, string>,
    );

export default parseCookie;
