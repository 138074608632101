import logger, { LoggerOptions } from "./logger";

interface ApiConfig {
  baseUrl?: string;
  useAccessToken: () => string | undefined;
  useHeaders: () => Record<string, string | undefined>;
}

interface PartialCommonConfig {
  api?: Partial<{
    core: Partial<ApiConfig>;
    partner: Partial<ApiConfig>;
  }>;
  logger?: LoggerOptions;
}

interface CommonConfig {
  api: {
    core: ApiConfig;
    partner: ApiConfig;
  };
}

let config: CommonConfig;

export const setConfig = (value: PartialCommonConfig) => {
  config = {
    api: {
      core: {
        useAccessToken: () => undefined,
        useHeaders: () => ({}),
        ...value.api?.core,
      },
      partner: {
        useAccessToken: () => undefined,
        useHeaders: () => ({}),
        ...value.api?.partner,
      },
    },
  };

  if (value.logger) {
    logger.init(value.logger);
  }
};

export const getConfig = (): CommonConfig => {
  return config;
};
