import graphql from "../graphql";

export const PAGES = graphql`
  fragment PAGES on PageRelationResponseCollection {
    data {
      attributes {
        title
        slug
      }
    }
  }
`;

export default PAGES;
