import { logger } from "@justhome/common";
import { LanguageISOCode } from "@justhome/common/api/core";
import { FaqRelationResponseCollection } from "@lib/generated/graphql";
import { TFunction } from "next-i18next";
import { Locale } from "next/router";
import { isClient } from "./settings";

const localeToLanguage = (locale: Locale | string) => {
  const locales = {
    de: "de-DE",
    en: "en-EN",
  };
  return locales[locale] || "de-DE";
};

export const formatDate = (
  input: string | number | undefined,
  locale: Locale | string,
) => {
  if (!input) {
    return null;
  }
  try {
    const language = localeToLanguage(locale);
    const date = new Date(input);
    return new Intl.DateTimeFormat(language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  } catch (error) {
    logger.error("Failed to format date", error);
    return null;
  }
};

export const formatDateTime = (
  input: string | number | undefined,
  locale: Locale | string,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!input) {
    return null;
  }
  try {
    const language = localeToLanguage(locale);
    const date = new Date(input);
    return new Intl.DateTimeFormat(language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      ...options,
    }).format(date);
  } catch (error) {
    logger.error("Failed to format date", error);
    return null;
  }
};

export const formatYear = (year: number | undefined, t: TFunction) => {
  if (!year) {
    return "";
  }

  const text = year <= 1 ? t("year") : t("years");
  return `${year} ${text}`;
};

export const saveToStorage = (key: string, value: string) => {
  if (isClient && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
};

export const getFromStorage = (key: string): string | null => {
  if (isClient && window.localStorage) {
    return window.localStorage.getItem(key);
  }
  return null;
};

export const getObjectFromStorage = <T extends object>(
  key: string,
): T | null => {
  const json = getFromStorage(key);
  if (json) {
    try {
      return JSON.parse(json);
    } catch (error) {
      logger.error(`Failed to parse ${key} from localStorage.`, error);
    }
  }
  return null;
};

export const saveObjectToStorage = (key: string, value: object) => {
  saveToStorage(key, JSON.stringify(value));
};

export const mapFaqs = (
  faqs: FaqRelationResponseCollection | null | undefined,
): Array<{ answer: string; question: string }> => {
  return (
    faqs?.data?.map((faq) => {
      return {
        answer: faq.attributes?.answer || "",
        question: faq.attributes?.question || "",
      };
    }) || []
  );
};

export const getPercent = (
  value: number | string | null | undefined,
  sum: number | string | null,
) => {
  return Number(((Number(value) / Number(sum)) * 100).toFixed(2));
};

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffle = <T,>(array: T[]): T[] => {
  const arrayCopy = [...array];
  let currentIndex = arrayCopy.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    const temp = arrayCopy[currentIndex]!;
    arrayCopy[currentIndex] = arrayCopy[randomIndex]!;
    arrayCopy[randomIndex] = temp;
  }

  return arrayCopy;
};

export const localeToLanguageISOCode = (locale: Locale) => {
  return locale === "immo" ? "DE" : (locale.toUpperCase() as LanguageISOCode);
};

export const paginate = (
  array: any[],
  pageSize: number,
  pageNumber: number,
) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};
