import { useEffect } from "react";
import { useRouter } from "next/router";
import { REINITIALIZE_COOKIE_SETTINGS_KEY } from "@lib/constants";

declare global {
  interface Window {
    Cookiebot?: {
      show(isRenewal: boolean): void;
    };
  }
}

const useCookiebotRenewal = () => {
  const router = useRouter();

  useEffect(() => {
    const onHashChangeStart = (url: string) => {
      const hash = url?.split("#")?.[1];
      if (hash === REINITIALIZE_COOKIE_SETTINGS_KEY) {
        if (window.Cookiebot) {
          window.Cookiebot.show(true);
        }
      }
    };

    router?.events?.on("hashChangeStart", onHashChangeStart);

    return () => {
      router?.events?.off("hashChangeStart", onHashChangeStart);
    };
  }, [router?.events]);
};

export default useCookiebotRenewal;
