import graphql from "../graphql";

export const OPEN_QUESTION = graphql`
  fragment OPEN_QUESTION on ComponentSpecialBlocksOpenQuestion {
    title
    description
    button {
      ...BUTTON
    }
    buttons {
      ...BUTTON
    }
    image {
      ...SIMPLE_IMAGE
    }
  }
`;

export default OPEN_QUESTION;
