import graphql from "../graphql";

export const BUTTON = graphql`
  fragment BUTTON on ComponentBlocksButton {
    label
    variant
    size
    iconStart
    iconEnd
    href
    trackingId
    action
    page {
      data {
        attributes {
          slug
        }
      }
    }
  }
`;

export default BUTTON;
