import { isServer } from "./shared";
import { Tracker } from "./types";

interface Fathom {
  trackEvent(eventName: string): void;
  isTrackingEnabled(): boolean;
  trackPageview(): void;
  blockTrackingForMe(): void;
}

declare global {
  interface Window {
    fathom?: Fathom;
  }
}

class FathomTracker implements Tracker {
  isEnabled() {
    if (isServer) {
      return false;
    }
    return !window.fathom?.isTrackingEnabled();
  }

  disable() {
    if (isServer) {
      return;
    }
    window.fathom?.blockTrackingForMe();
  }

  trackEvent(eventName: string) {
    if (isServer || !window.fathom) {
      return;
    }

    if (!this.isEnabled()) {
      /* eslint-disable-next-line no-console */
      console.debug(`Tracking fathom event: %c${eventName}`, "color: #1A9883");
    }

    window.fathom?.trackEvent(eventName);
  }

  trackPageview() {
    if (isServer) {
      return;
    }
    window.fathom?.trackPageview();
  }

  init() {}
}

export default FathomTracker;
