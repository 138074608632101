import { createContext } from "react";
import {
  AccountData,
  ArticleData,
  CategoriesData,
  JobData,
  PageData,
  SiteData,
} from "src/types";

export interface GlobalContextValue {
  [x: string]: any;
  siteData?: SiteData | null;
  accountData?: AccountData | null;
  categoriesData?: CategoriesData;
  jobData?: JobData;
  pageData?: PageData;
  articleData?: ArticleData;
  random: number;
}

const defaultValue: GlobalContextValue = {
  siteData: null,
  setProviderValue: () => {},
  random: 0,
};

export const GlobalContext = createContext<GlobalContextValue>(defaultValue);

GlobalContext.displayName = "Global";
