import graphql from "../graphql";

export const PAGE_LINK = graphql`
  fragment PAGE_LINK on ComponentNavigationsPageLink {
    page {
      ...PAGE
    }
  }
`;

export default PAGE_LINK;
