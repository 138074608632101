import { logger } from "@justhome/common";
import Cookies from "js-cookie";
import { ParsedUrlQuery } from "querystring";
import { COOKIE_GOOGLE_ANALYTICS, LOCAL_STORAGE_UTM } from "../constants";

export interface UTMParams {
  campaign?: string;
  content?: string;
  medium?: string;
  source?: string;
  term?: string;
}
export interface Origin extends UTMParams {
  googleAnalyticsID?: string;
}

let utmParams: UTMParams | null = null;

export const parseUTM = (nextQuery: ParsedUrlQuery): UTMParams => {
  if (!nextQuery || (nextQuery && Object.keys(nextQuery).length === 0)) {
    return {};
  }
  const utm: ParsedUrlQuery = {
    campaign: nextQuery["utm_campaign"],
    content: nextQuery["utm_content"],
    medium: nextQuery["utm_medium"],
    source: nextQuery["utm_source"],
    term: nextQuery["utm_term"],
  };

  return utm;
};

export const saveUTM = (utm?: UTMParams) => {
  try {
    if (!utm || (utm && Object.keys(utm).length === 0)) {
      return null;
    }
    if (typeof sessionStorage === "undefined") {
      utmParams = utm;
    } else {
      sessionStorage.setItem(LOCAL_STORAGE_UTM, JSON.stringify(utm));
    }
  } catch (error) {
    logger.error("Cannot save UTM to storage", error);
    return null;
  }
};

const getGoogleAnalyticsID = () => {
  return Cookies.get(COOKIE_GOOGLE_ANALYTICS);
};

export const getOrigin = (): Origin => {
  const cookieGA = getGoogleAnalyticsID();
  const utm = getUTM();
  const origin: Origin = utm ?? {};

  if (cookieGA) {
    origin.googleAnalyticsID = cookieGA;
  }
  return origin;
};

export const getUTM = (): UTMParams | null => {
  try {
    if (typeof sessionStorage === "undefined") {
      return utmParams;
    }
    const utmJson = sessionStorage.getItem(LOCAL_STORAGE_UTM);
    if (utmJson) {
      return JSON.parse(utmJson);
    }
  } catch (error) {
    logger.error("Cannot read UTM from storage", error);
  }

  return null;
};
