/* eslint-disable no-console */

import { datadogLogs } from "@datadog/browser-logs";

const isClient = typeof window !== "undefined";
const env =
  isClient && window.location.hostname.endsWith("justhome.com")
    ? "production"
    : "development";

export type LoggerOptions = {
  service: string;
  version?: string;
  datadogDisabled?: boolean;
};

class Logger {
  private datadogDisabled: boolean = false;

  init({ service, version, datadogDisabled }: LoggerOptions) {
    if (datadogDisabled) {
      this.datadogDisabled = true;
      return;
    }

    datadogLogs.init({
      clientToken: "pub94c6cb288aceacadfca91f77ecd90e97",
      site: "datadoghq.eu",
      service: `frontend:${service}`,
      env,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      version,
    });
  }

  error(message: string, error: Error | unknown, context?: object): void {
    if (env === "development") {
      if (context) {
        console.error(context);
      }
      console.error(error);
    }

    if (error instanceof DOMException && error.ABORT_ERR) {
      return;
    }

    if (isClient && !this.datadogDisabled) {
      const err = this._unknownToError(error);
      datadogLogs.logger.error(message, context, err);
    }
  }

  warn(message: string) {
    if (isClient && !this.datadogDisabled) {
      datadogLogs.logger.warn(message);
    }

    if (env === "development") {
      console.warn(message);
    }
  }

  private _unknownToError(error: Error | unknown): Error {
    if (!error) {
      return new Error("unknown error");
    }

    if (error instanceof Error) {
      return error;
    }

    if (typeof error === "string") {
      return new Error(error);
    }

    if (
      typeof error === "object" &&
      "message" in error &&
      typeof error.message === "string"
    ) {
      return new Error(error.message);
    }

    return new Error(String(error));
  }
}

const logger = new Logger();

export default logger;
