import { ElementType } from "react";

interface Config {
  linkComponent?: ElementType;
  imageComponent?: ElementType;
}

let config: Config = {};

export const setConfig = (value: Config) => {
  config = value;
};

export const getLinkComponent = () => {
  return config.linkComponent || "a";
};

export const getImageComponent = () => {
  return config.imageComponent || "img";
};
