import Cookies from "js-cookie";
import { COOKIE_E2E_TEST } from "./constants";

let reducedMotion = false;

export const isClient = typeof window !== "undefined";

if (isClient) {
  reducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
}

export const prefersReducedMotion = reducedMotion;

export const isTest = typeof Cookies.get(COOKIE_E2E_TEST) === "string";

export const isUnitTest = process.env.JEST_WORKER_ID !== undefined;

export const isProd =
  process.env.VERCEL_ENV === "production" ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
