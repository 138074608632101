import graphql from "../graphql";

export const SOCIAL_LINK = graphql`
  fragment SOCIAL_LINK on ComponentNavigationsSocialLink {
    url
    icon
  }
`;

export default SOCIAL_LINK;
