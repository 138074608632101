import {
  GetGlobalQuery,
  GetGlobalQueryVariables,
} from "@lib/generated/graphql";
import gqlFetch from "./gqlFetch";
import globalQuery from "./queries/globalQuery";

const getGlobalData = async (locale: string) => {
  const globalData = await gqlFetch<GetGlobalQuery, GetGlobalQueryVariables>(
    globalQuery,
    { locale },
  );
  return globalData;
};
export default getGlobalData;
