import ButtonId from "./buttonId";

export const DEFAULT_LOCALE = "de";
export const COOKIE_APPLICATION_SETTINGS_KEY =
  "justhomeApplicationCookieSettings";
export const COOKIE_E2E_TEST = "e2e";
export const COOKIE_GOOGLE_ANALYTICS = "_ga";
export const REINITIALIZE_COOKIE_SETTINGS_KEY = "reinitialize_cookie_settings";
export const COOKIE_SETTINGS_EXPIRY = 730;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 9;
export const OFFERS_PAGE_SIZE = 10;
export const DEFAULT_ARTICLE_LIST_SIZE = 8;
export const LAST_VARIANT = "last_variant";
export const LAST_PROJECT = "last_project";
export const GTM_ID = "GTM-NCB5ZWL";
export const NEXT_LOCALE = "NEXT_LOCALE";
export const ARTICLES_SORT = ["publishedAt"];
export const LOCAL_STORAGE_UTM = "utm";
export const ACCOUNT_STATE = "accountState";
export const GTM_EVENT_RESULTS_EMAIL = "results_sent_email";
export const GTM_EVENT_CONTACT_WALL = "contact_wall";
export const WHATSAPP_LINK = "https://wa.me/493091739560";
export const WEBSITE_HOST = "https://justhome.com";

export enum FathomEvent {
  BURGER_MENU = "Burger_menu",
  NAVIGATION_TABS = "Navigation_tabs",
  COMPARE = "FUNNEL_First_page_seen",
  BOOKING_SUCCESS = "CALL_BOOKING_success",
  RESULTS_PAGE = "FUNNEL_complete",
  RESULTS = "Results_shown",
  NO_RESULTS = "No_results_shown",
  CERTIFICATE_SUCCESS = "FINCERT_success",
  RESULTS_SIGN_IN = "New_acct_created",
  APPLICATION_STARTED = "FUNNEL_First_page_action",
  RESULTS_EMAIL = "Results_Email_info_requested",
  RESULTS_NEXT_STEPS = "Results_mobile_nextsteps",
  RESULTS_WHATSAPP = "Results_wa_contact",
  OFFER_PREVIEW_SELECT = "Results_option_selected",
  ACCOUNT_BOOKING = "Acct_call_booked",
  BOOK_CALL_INTENTION = "CALL_BOOKING_intention",
  RESULTS_PAGE_DIRECT_ENTRY = "Results_page_direct_entry",
  LEAD_WALL_SEEN = "FUNNEL_Lead_wall_seen",
  LEAD_WALL_DETAILS_SEEN = "FUNNEL_Lead_wall_seen_details",
  LEAD_WALL_SEEN_EMAIL_ONLY = "FUNNEL_Lead_wall_seen_onlyemail",
  CONTRACT_ONOFFICE_CALCULATOR = "Contract_OnOffice_Finanzrechner",
  CONTRACT_PROPSTACK_CALCULATOR = "Contact_Propstack_Finanzrechner",
  CONTRACT_ONOFFICE_CALCULATOR_CALL = "Contract_OnOffice_Finanzrechner_Call",
  CONTRACT_PROPSTACK_CALCULATOR_CALL = "Contact_Propstack_Finanzrechner_Call",
  CONTRACT_ONOFFICE_FINANCE_CHECK_CALL = "Contract_OnOffice_Finanzcheck_Call",
  CONTRACT_PROPSTACK_FINANCE_CHECK_CALL = "Contract_Propstack_Finanzcheck_Call",

  // Medal rating events
  MEDAL_RATING_INTRO_BOOK_A_MEETING = "MedalRating_Intro_BookAMeeting",
  MEDAL_RATING_INTRO_SEE_RATES = "MedalRating_Intro_SeeRates",
  MEDAL_RATING_INTRO_RESULTS_BOOK_A_MEETING = "MedalRating_IntroResults_BookAMeeting",
  MEDAL_RATING_INTRO_RESULTS_PERFORM_BUDGET_CHECK = "MedalRating_IntroResults_PerformBudgetCheck",
  MEDAL_RATING_INTRO_RESULTS_CHANGE_INITIAL_INPUT = "MedalRating_IntroResults_ChangeInitialInput",
  MEDAL_RATING_INTRO_RESULTS_CHANGE_INITIAL_INPUT_NO_RATES = "MedalRating_IntroResults_ChangeInitialInputNoRates",
  MEDAL_RATING_BUDGET_CHECK_BOOK_A_MEETING = "MedalRating_BudgetCheck_BookAMeeting",
  MEDAL_RATING_BUDGET_CHECK_NEXT = "MedalRating_BudgetCheck_Next",
  MEDAL_RATING_BUDGET_CHECK_RESULTS_BOOK_A_MEETING = "MedalRating_BudgetCheckResults_BookAMeeting",
  MEDAL_RATING_BUDGET_CHECK_RESULTS_INFORM_REALTOR = "MedalRating_BudgetCheckResults_InformRealtor",
  MEDAL_RATING_BUDGET_CHECK_RESULTS_CHANGE_BUDGET_CHECK = "MedalRating_BudgetCheckResults_ChangeBudgetCheck",
  MEDAL_RATING_BUDGET_CHECK_RESULTS_CHANGE_BUDGET_CHECK_NO_RESULTS = "MedalRating_BudgetCheckResults_ChangeBudgetCheckNoResults",
  MEDAL_RATING_FINANCING_CERTIFICATE_PREVIEW_BOOK_A_MEETING = "MedalRating_FinancingCertificatePreview_BookAMeeting",
  MEDAL_RATING_FINANCING_CERTIFICATE_PREVIEW_REQUEST = "MedalRating_FinancingCertificatePreview_Request",
  MEDAL_RATING_FINANCING_CERTIFICATE_INFORM_REALTOR = "MedalRating_FinancingCertificate_InformRealtor",
  MEDAL_RATING_BOOK_A_CALL_BACK_TO_STEPPER = "MedalRating_BookACall_BackToStepper",

  // Onboarding assistant events
  ONBOARDING_ASSISTANT_WELCOME_STEP_COMPLETED = "OnboardingAssistant_WelcomeStepCompleted",
  ONBOARDING_ASSISTANT_FIRST_APPLICANT_PERSONAL_INFO_STEP_COMPLETED = "OnboardingAssistant_FirstApplicant_PersonalInfoStepCompleted",
  ONBOARDING_ASSISTANT_FIRST_APPLICANT_ADDRESS_STEP_COMPLETED = "OnboardingAssistant_FirstApplicant_AddressStepCompleted",
  ONBOARDING_ASSISTANT_FIRST_APPLICANT_EMPLOYMENT_STEP_COMPLETED = "OnboardingAssistant_FirstApplicant_EmploymentStepCompleted",
  ONBOARDING_ASSISTANT_SECOND_APPLICANT_PERSONAL_INFO_STEP_COMPLETED = "OnboardingAssistant_SecondApplicant_PersonalInfoStepCompleted",
  ONBOARDING_ASSISTANT_SECOND_APPLICANT_ADDRESS_STEP_COMPLETED = "OnboardingAssistant_SecondApplicant_AddressStepCompleted",
  ONBOARDING_ASSISTANT_SECOND_APPLICANT_EMPLOYMENT_STEP_COMPLETED = "OnboardingAssistant_SecondApplicant_EmploymentStepCompleted",
  ONBOARDING_ASSISTANT_ADDITIONAL_APPLICANT_DECISION_STEP_COMPLETED = "OnboardingAssistant_AdditionalApplicantDecisionStepCompleted",
  ONBOARDING_ASSISTANT_KIDS_STEP_COMPLETED = "OnboardingAssistant_KidsStepCompleted",

  ONBOARDING_ASSISTANT_BACK_NAVIGATION = "OnboardingAssistant_BackNavigation",
  ONBOARDING_ASSISTANT_FORM_SUBMITTED = "OnboardingAssistant_FormSubmitted",
  ONBOARDING_ASSISTANT_FORM_SUBMISSION_SUCCEEDED = "OnboardingAssistant_FormSubmissionSucceeded",
  ONBOARDING_ASSISTANT_FORM_SUBMISSION_FAILED = "OnboardingAssistant_FormSubmissionFailed",

  ONBOARDING_ASSISTANT_NPS_SURVEY_SUBMITTED = "OnboardingAssistant_NPSSurveySubmitted",

  // Onboarding assistant V2 events
  ONBOARDING_ASSISTANT_CHAT_STARTED = "OnboardingAssistantChat_Started",
  ONBOARDING_ASSISTANT_CHAT_MESSAGE_SENT = "OnboardingAssistantChat_MessageSent",
  ONBOARDING_ASSISTANT_CHAT_EXIT_MODAL_OPENED = "OnboardingAssistantChat_ExitModalOpened",
  ONBOARDING_ASSISTANT_CHAT_EXITED_MANUALLY = "OnboardingAssistantChat_ExitedManually",
  ONBOARDING_ASSISTANT_CHAT_COMPLETED = "OnboardingAssistantChat_Completed",
}

export const FATHOM_CLICK_EVENTS: Partial<Record<ButtonId, FathomEvent>> = {
  results_next_steps: FathomEvent.RESULTS_NEXT_STEPS,
  results_whatsapp: FathomEvent.RESULTS_WHATSAPP,
  offer_preview_select: FathomEvent.OFFER_PREVIEW_SELECT,
  account_booking: FathomEvent.ACCOUNT_BOOKING,
};
