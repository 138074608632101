import { logger } from "@justhome/common";
import { STRAPI_URL } from "./constants";

function getStrapiURL(path: string) {
  return STRAPI_URL + path;
}

const gqlFetch = async <T, V extends object>(
  query: any,
  variables: V,
): Promise<T | null> => {
  const vars = {
    ...variables,
    locale:
      (variables as any).locale === "immo"
        ? "de-LI"
        : (variables as any).locale,
  };
  const gqlEndpoint = getStrapiURL("/graphql");

  const res = await fetch(gqlEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables: vars,
    }),
  });

  const response = await res.json();
  if (response.errors) {
    const error = new Error("Graphql failed to fetch");
    logger.error("", error, response.errors);
    throw error;
  }

  return response.data;
};

export default gqlFetch;
