import graphql from "../graphql";

export const PAGE = graphql`
  fragment PAGE on PageEntityResponse {
    data {
      attributes {
        localizations {
          data {
            attributes {
              locale
              title
              slug
            }
          }
        }
        title
        slug
      }
    }
  }
`;

export default PAGE;
