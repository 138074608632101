import BUTTON from "../fragments/button";
import OPEN_QUESTION from "../fragments/openQuestion";
import PAGE from "../fragments/page";
import PAGE_LINK from "../fragments/pageLink";
import PAGES from "../fragments/pages";
import SIMPLE_IMAGE from "../fragments/simpleImage";
import SOCIAL_LINK from "../fragments/socialLink";
import graphql from "../graphql";

const globalQuery = graphql`
  query GetGlobal($locale: I18NLocaleCode) {
    footerV2(locale: $locale) {
      data {
        id
        attributes {
          copyright
          linkGroups {
            title
            links {
              ...PAGE_LINK
            }
          }
          socialLinks {
            ...SOCIAL_LINK
          }
          secondaryLinks {
            ...PAGES
          }
        }
      }
    }
    global {
      data {
        id
        attributes {
          meetingUrl
          financeCheckMeetingUrl
          featureToggles
        }
      }
    }
    applicationProcess(locale: $locale) {
      data {
        attributes {
          openQuestion {
            ...OPEN_QUESTION
          }
        }
      }
    }
  }
  ${BUTTON}
  ${PAGE}
  ${PAGE_LINK}
  ${SOCIAL_LINK}
  ${OPEN_QUESTION}
  ${SIMPLE_IMAGE}
  ${PAGES}
`;

export default globalQuery;
