import { appWithTranslation } from "next-i18next";
import App, {
  AppContext as ApplicationContext,
  AppProps,
  AppType,
} from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

import "tailwind-base/theme.css";
import "../styles/alice-carousel.css";
import "../styles/cookiebot.css";
import "../styles/global.css";
import "../styles/push-carousel.css";

import ErrorBoundary from "@components/common/ErrorBoundary";
import Fathom from "@components/common/Fathom";
import {
  disableTracking,
  isTrackingEnabled,
  logger,
  setConfig as setCommonConfig,
  umami,
} from "@justhome/common";
import { GlobalContext } from "@lib/context/global/context";
import useCookiebotRenewal from "@lib/hooks/useCookiebotRenewal";
import useIsTest from "@lib/hooks/useIsTest";
import parseCookie from "@lib/parseCookie";
import { isProd } from "@lib/settings";
import getGlobalData from "@lib/strapi/getGlobalData";
import { parseUTM, saveUTM } from "@lib/utm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { setConfig } from "@justhome/react-ui";
import useExperimentSeed from "@lib/hooks/useExperimentSeed";
import { ApplicationProvider } from "@modules/application/context/ApplicationContext";
import Image from "next/image";
import Link from "next/link";

setCommonConfig({
  api: {
    core: {
      baseUrl: process.env.NEXT_PUBLIC_CORE_BASE_URL,
    },
  },
  logger: {
    service: "justhome",
    version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  },
});

setConfig({
  linkComponent: Link,
  imageComponent: Image,
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProd,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    umami.trackEvent(experiment.key, {
      variationId: result.variationId,
    });
  },
});

interface MyAppProps {
  siteData: any;
  random: number;
}

function MyApp({ Component, pageProps: pageProps }: AppProps<MyAppProps>) {
  const router = useRouter();
  useCookiebotRenewal();
  const isTest = useIsTest();
  const experimentSeed = useExperimentSeed();
  useEffect(() => {
    if (router.query) {
      const utm = parseUTM(router.query);
      saveUTM(utm);
    }
  }, [router.query]);

  useEffect(() => {
    let interval: number;

    if (isTest) {
      interval = window.setInterval(() => {
        try {
          if (window.cookiebot) {
            window.cookiebot.hide();
            window.cookiebot.withdraw();
          }
          if (isTrackingEnabled()) {
            disableTracking();
          }
        } catch (error) {
          logger.error(`Error in ${MyApp.name} component.`, error);
        }

        if (window.cookiebot && window.fathom) {
          clearInterval(interval);
        }
      }, 500);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTest]);

  useEffect(() => {
    growthbook.init();
  }, []);

  useEffect(() => {
    growthbook.setURL(window.location.href);
    growthbook.setAttributes({
      id: experimentSeed,
      path: router.asPath,
      browser: navigator.userAgent,
      enableDevMode: !isProd,
      query: router.query,
    });
  }, [experimentSeed, router.asPath, router.query]);

  return (
    <div>
      <Fathom />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1"
        />
      </Head>
      <ErrorBoundary>
        <GrowthBookProvider growthbook={growthbook}>
          <QueryClientProvider client={queryClient}>
            <GlobalContext.Provider value={pageProps}>
              <ApplicationProvider
                initialState={(Component as any).applicationContextInitialValue}
              >
                <Component {...pageProps} />
              </ApplicationProvider>
            </GlobalContext.Provider>
          </QueryClientProvider>
        </GrowthBookProvider>
      </ErrorBoundary>
    </div>
  );
}

MyApp.getInitialProps = async (appContext: ApplicationContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const locale = appContext.ctx.locale || "";
  const siteData = await getGlobalData(locale);

  if (!isProd) {
    const cookies = parseCookie(appContext.ctx.req?.headers.cookie);
    const devState = cookies["dev-state"];
    let featureToggles = null;
    if (devState) {
      featureToggles = JSON.parse(devState).featureToggles;
    }

    if (siteData?.global?.data?.attributes && featureToggles) {
      siteData.global.data.attributes.featureToggles = featureToggles;
    }
  }

  return {
    ...appProps,
    pageProps: {
      siteData,
      random: Math.random(),
    },
  };
};

// appWithTranslation does not like generic AppProps
export default appWithTranslation(MyApp) as AppType;
